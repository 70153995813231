export const matiClient = process.env.VUE_APP_MATI_CLIENT
export const matiIneFlow = process.env.VUE_APP_MATI_INE_FLOW
export const matiDriveFlow = process.env.VUE_APP_MATI_DRIVE_FLOW
export const matiPassFlow = process.env.VUE_APP_MATI_PASSPORT_FLOW
export const pusherApiKey = process.env.VUE_APP_PUSHER_API_KEY
export const pusherAppId = process.env.VUE_APP_PUSHER_APP_ID
export const pusherSecret = process.env.VUE_APP_PUSHER_SECRET
export const pusherCluster = process.env.VUE_APP_PUSHER_CLUSTER
export const configcatKey = process.env.VUE_APP_CONFIGCAT_KEY
export const currentEnv = process.env.VUE_APP_CURRENT_ENV
export const hotjarSiteId = process.env.VUE_APP_HOTJAR_SITE_ID
export const hotjarSiteVersion = process.env.VUE_APP_HOTJAR_SITE_VERSION
export const mixpanelSiteId = process.env.VUE_APP_MIXPANEL_SITE_ID
export const captchaSecretKey = process.env.VUE_APP_CAPTCHA_SECRET_KEY
export const maxLoginRetries = process.env.VUE_APP_MAX_LOGIN_RETRIES
export const apiBaseURL = process.env.VUE_APP_API_V2_BASE_URL
export const incodeApiKey = process.env.VUE_APP_INCODE_API_KEY
export const incodeApiURL = process.env.VUE_APP_INCODE_API_URL
export const incodeApiVersion = process.env.VUE_APP_INCODE_API_VERSION
export const carrierIncodeFlowId = process.env.VUE_APP_CARRIER_INCODE_FLOW_ID
export const shareholderIncodeFlowId = process.env.VUE_APP_SHAREHOLDER_INCODE_FLOW_ID
export const i18nLocale = process.env.VUE_APP_I18N_LOCALE
export const i18nFallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE
export const luzmoCreditSummaryDashboardId = process.env.VUE_APP_LUZMO_CREDIT_SUMMARY_DASHBOARD_ID
export const luzmoPaymentsMadeToSolventoDashboardId = process.env.VUE_APP_LUZMO_PAYMENTS_MADE_TO_SOLVENTO_DASHBOARD_ID
export const luzmoPaymentsMadeToSuppliersDashboardId = process.env.VUE_APP_LUZMO_PAYMENTS_MADE_TO_SUPPLIERS_DASHBOARD_ID
export const luzmoOperationalSummaryDashboardId = process.env.VUE_APP_LUZMO_OPERATIONAL_SUMMARY_DASHBOARD_ID
export const luzmoFreightInsightsDashboardId = process.env.VUE_APP_LUZMO_FREIGHT_INSIGHTS_DASHBOARD_ID
export const luzmoFinancialInsightsDashboardId = process.env.VUE_APP_LUZMO_FINANCIAL_INSIGHTS_DASHBOARD_ID
export const luzmoPlaceholderDashboardId = process.env.VUE_APP_LUZMO_PLACEHOLDER_DASHBOARD_ID
export const luzmoURL = process.env.VUE_APP_LUZMO_URL
export const maximumAllowedForDownloadingInvoices = process.env.VUE_APP_MAXIMUM_ALLOWED_FOR_DOWNLOADING_INVOICES
export const awsGenerateDocumentsUrl = process.env.VUE_APP_AWS_GENERATE_DOCUMENTS_URL
export const bulkServiceUrl = process.env.VUE_APP_BULK_SERVICE_URL
export const availableForAccountStatements = process.env.VUE_APP_AVAILABLE_FOR_ACCOUNT_STATEMENTS
export const incodeFlowId = process.env.VUE_APP_INCODE_FLOW_ID
export const invoicesTemplateUrl = process.env.VUE_APP_INVOICES_TEMPLATE_URL
export const invoicesTemplateUrlForAuditProduct = process.env.VUE_APP_AUDIT_INVOICES_TEMPLATE_URL
export const invoicesDescriptionTemplateUrl = process.env.VUE_APP_INVOICES_DESCRIPTION_TEMPLATE_URL
export const invoicesDescriptionTemplateUrlForAuditProduct = process.env.VUE_APP_AUDIT_INVOICES_DESCRIPTION_TEMPLATE_URL
export const invoicesRelationalTemplateUrl = process.env.VUE_APP_INVOICES_RELATIONAL_TEMPLATE_URL
export const invoicesRelationalDescriptionTemplateUrl = process.env.VUE_APP_INVOICES_RELATIONAL_DESCRIPTION_TEMPLATE_URL
export const suppliersTemplateUrl = process.env.VUE_APP_SUPPLIERS_TEMPLATE_URL
export const suppliersTemplateUrlE2E = process.env.VUE_APP_E2E_SUPPLIERS_TEMPLATE_URL
export const suppliersDescriptionTemplateUrl = process.env.VUE_APP_SUPPLIERS_DESCRIPTION_TEMPLATE_URL
export const suppliersDescriptionTemplateUrlE2E = process.env.VUE_APP_E2E_SUPPLIERS_DESCRIPTION_TEMPLATE_URL
export const workflowServiceUrl = process.env.VUE_APP_WORKFLOW_SERVICE_URL



export const deliveriesTemplate = process.env.VUE_APP_DELIVERIES_TEMPLATE
export const deliveriesDescriptionTemplate = process.env.VUE_APP_DELIVERIES_DESCRIPTION_TEMPLATE

export default {
  apiBaseURL,
  matiClient,
  matiIneFlow,
  matiDriveFlow,
  matiPassFlow,
  pusherApiKey,
  pusherAppId,
  pusherSecret,
  pusherCluster,
  configcatKey,
  currentEnv,
  hotjarSiteId,
  hotjarSiteVersion,
  mixpanelSiteId,
  captchaSecretKey,
  maxLoginRetries,
  incodeApiURL,
  incodeApiKey,
  incodeApiVersion,
  carrierIncodeFlowId,
  shareholderIncodeFlowId,
  i18nFallbackLocale,
  i18nLocale,
  luzmoCreditSummaryDashboardId,
  luzmoPaymentsMadeToSolventoDashboardId,
  luzmoPaymentsMadeToSuppliersDashboardId,
  luzmoOperationalSummaryDashboardId,
  luzmoFreightInsightsDashboardId,
  luzmoFinancialInsightsDashboardId,
  luzmoPlaceholderDashboardId,
  luzmoURL,
  maximumAllowedForDownloadingInvoices,
  awsGenerateDocumentsUrl,
  bulkServiceUrl,
  availableForAccountStatements,
  incodeFlowId,
  invoicesTemplateUrl,
  invoicesTemplateUrlForAuditProduct,
  invoicesDescriptionTemplateUrl,
  invoicesDescriptionTemplateUrlForAuditProduct,
  invoicesRelationalTemplateUrl,
  invoicesRelationalDescriptionTemplateUrl,
  suppliersTemplateUrl,
  suppliersTemplateUrlE2E,
  suppliersDescriptionTemplateUrl,
  suppliersDescriptionTemplateUrlE2E,
  deliveriesTemplate,
  deliveriesDescriptionTemplate,
  workflowServiceUrl
}

