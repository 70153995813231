import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV1} from "@/shared/general-request";
export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchCarriers({commit}) {
            commit('isLoading', true);
            commit('error', null);
            httpClientV1.get(`/carriers/reports`)
                .then(async function (response) {
                    commit('isLoading', false);
                    commit('items', response.data);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
    }
}