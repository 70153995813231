import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV2} from "@/shared/general-request";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {

        fetchDocumentsTable({commit}, query = '') {
            commit('isLoading', true);
            commit('error', null);
            const url = `/search/deliveries?${query}`;

            httpClientV2.get(url)
                .then(async function (response) {
                    commit('isLoading', false);
                    commit('items', response.results);
                    commit('total', response.total);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: {data: error?.response}};
                    commit('app/reportMessage', e, {root: true});
                });
        },
    }
}
