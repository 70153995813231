<template>
   <router-view/>
</template>

<script>
export default {
    name: 'app-layout'
}
</script>

<style>

</style>