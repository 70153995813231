import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV2} from "@/shared/general-request";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchChildrenDocuments({commit}, query) {
            commit('isLoading', true);
            commit('error', null);

            httpClientV2.get(query)
                .then(async function (response) {
                    commit('isLoading', false);
                    commit('items', response);
                    commit('total', response.total);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: {data:error?.response?.data}};
                    commit('app/reportMessage', e, {root: true});
                });
        },
        async editDocument({commit}, payload) {
            commit('isSaving', true);
            commit('error', null);
            const {data, query} = payload;
            return httpClientV2.patch(`/${query}`, data);
        }
    }
}
