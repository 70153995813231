import axios from 'axios';
import Vue from 'vue';
import store from '@/plugins/store';

export default {
    axiosInstance: null,
    requestInterceptor: request => {
        let jwt = store.getters['security/getJwt'];
        if (jwt) {
            request.headers.common['Authorization'] = "Bearer " + jwt;
        }
        return request;
    },
    init() {
        this.axiosInstance = axios.create({});
        this.axiosInstance.interceptors.request.use(this.requestInterceptor);
        Vue.prototype.$axios = this.axiosInstance;
    }
};