import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV1} from "@/shared/general-request";

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations
  },
  actions: {

    fetchSuppliers({commit}, query) {
      commit('isLoading', true);
      commit('error', null);
      commit('items', []);
      httpClientV1.get(`/accounts_payable/providers${query}`)
        .then(async function (response) {
          commit('isLoading', false);
          commit('items', response.data);
        })
        .catch((error) => {
          commit('isLoading', false);
          commit('error', error?.response?.code || error.message)
        });
    },
    downloadCEP({commit}) {
      console.log('commit', commit);
    },
    downloadProviders({commit}, business_id) {
      commit('isLoading', {provider: true});
      httpClientV1.get(`/accounts_payable/providers/template?business_id=${business_id}`, {fullResponse: true})
        .then(async function (response) {
          let blob = new Blob([response.data], {type: response.headers['content-type']});
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'proveedores.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          commit('isLoading', false);
        })
        .catch(() => {
          commit('isLoading', false);
        });
    }
  }
}