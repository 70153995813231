import axios from "axios";
import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations
  },
  actions: {
    fetchMatiConfig({commit}) {
      // const headers = {Authorization: `Bearer ${token}`};
      axios(
        {
          method: "GET",
          url: "/api/v1/mati_vars",
        })
        .then(async function (response) {
          // commit('item', response.data.data);
          commit('error', null);
          commit('isLoadingSingle', false);
          commit('item', response.data.data);
        })
        .catch(() => {
          commit('isLoadingSingle', false);
          // commit('error', error?.response?.data?.code || error.message)
        }
      );
    },
  }
}