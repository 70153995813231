import VueJwtDecode from 'vue-jwt-decode'
import router from '@/plugins/router'
import {MyFunctions} from "@/components/app/classes/utils/functions";
import {httpClientV1} from "@/shared/general-request";

export default {
    namespaced: true,
    state: {
        jwt: null,
        refreshToken: null,
        session: {
            email: '',
            permissions: '',
            referral_code_link: ''
        },
    },
    mutations: {
        setJwt(state, jwt) {
            state.jwt = jwt;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
        },
        setSession(state, s) {
            state.session = s;
        }
    },
    actions: {
        login: async ({commit}, credentials) => {
            try {
                commit('app/clearMessages', null, {root: true});
                const response = await httpClientV1.post('/users/login', {
                    email: credentials.email,
                    password: credentials.password
                })
                if (response.status === "success") {
                    let {email, permissions, referral_code_link, token, refreshToken} = response.data;
                    commit('setJwt', token);
                    commit('setRefreshToken', refreshToken);
                    commit('setSession', {email, permissions, referral_code_link});
                    await router.push({name: 'kyc'});
                    const {id} = VueJwtDecode.decode(token);
                    MyFunctions.identifyDnDataAnalysisSites(id, {email});
                    MyFunctions.setPersonalInfoDnDataAnalysisSites({
                        '$email': email,
                    });
                    MyFunctions.dispatchTrackEvent("IniciaSesion.Exito");
                }
                return true
            } catch (error) {
                MyFunctions.dispatchTrackEvent("IniciaSesion.Error");
                commit('app/reportMessage', error.response?.data?.code, {root: true});
                return false;
            }
        },
        apiLogout: function ({state}) {
            const {refreshToken} = state;
            if (refreshToken) {
                try {
                    httpClientV1.post('/auth/logout', {refreshToken})
                        .catch(() => {
                        });
                } catch (e) {
                    console.log(e);
                }

            }
        },
        register: async ({commit}, {token, password}) => {
            try {
                commit('app/clearMessages', null, {root: true});
                await httpClientV1.patch('/users/password', {
                    token,
                    password
                });
                return true;
            } catch (error) {
                commit('app/reportMessage', 'Algo salió mal durante la operación', {root: true});
            }
        },
        verifyToken: async ({commit}, token) => {
            try {
                commit('app/clearMessages', null, {root: true});
                let response = await httpClientV1.post('/users/verification/email', {
                    token
                });
                return response?.email;
            } catch (error) {
                console.log(error);
                commit('app/reportMessage', 'Algo salió mal durante la operación', {root: true});
                return false;
            }
        },
        logout: ({commit, dispatch}) => {
            dispatch('apiLogout');
            commit('setJwt', null);
            commit('setRefreshToken', null);
            commit('setSession', {email: '', permissions: '', referral_code_link: ''});
            router.push('/login').catch(() => {
            });
        }
    },
    getters: {
        getJwt(state) {
            return state.jwt;
        },
        session(state) {
            return state.session;
        }
    }
}