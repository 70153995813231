import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import icons from '@/plugins/vuetify/icons'
import 'font-awesome/css/font-awesome.min.css'
import '@mdi/font/css/materialdesignicons.css'
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

const opts = {
    icons: icons,
    theme: {
        options: {
            customProperties: true
        },
        default: 'light',
        themes: {
            light: {
                primary: '#00AEF1',
                secondary: '#324956',
                tertiary: '#FF8C00'
            },
        },
    },
    lang: {
        locales: { en, es },
        current: 'es',
    },
};

export default new Vuetify(opts);
