import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {MyFunctions} from "@/components/app/classes/utils/functions";
import {httpClientV1} from "@/shared/general-request";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetch({commit}, carrier_id) {
            commit('isLoading', true);
            let params = {carrier_id: carrier_id}

            await httpClientV1.get('/carriers/kyc_info', {params})
                .then(async function (response) {
                    commit('items', response.data);
                    commit('isLoading', false);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async updateStatusShareholder({commit}, params) {
            commit('isSaving', true);

            await httpClientV1.patch(`/shareholders/${params.shareholder_id}/kyc_entity`, params.form)
                .then(function () {
                    commit('isSaving', false);
                })
                .catch((error) => {
                    commit('isSaving', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async updateStatusCarrier({commit}, params) {
            commit('isSaving', true);

            await httpClientV1.patch(`/carriers/${params.shareholder_id}/kyc_entity`, params.form)
                .then(function () {
                    commit('isSaving', false);
                })
                .catch((error) => {
                    commit('isSaving', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async uploadDocumentCarrier({commit, state}, params) {
            let copiaStatuses = {...state.itemsStatuses}
            copiaStatuses[`${params.kyc_name}`].error = null

            await httpClientV1.post(`/carriers/${params.shareholder_id}/kyc_documents`, {...params})
                .then(async function (response) {
                    let foundIndex = state.items.kyc_info.findIndex(item => item.kyc_name === response.kyc_name)
                    state.items.kyc_info[foundIndex] = response
                    let element = {...state.items}
                    commit('items', element);

                    copiaStatuses[`${params.kyc_name}`].requestDocument = false
                    commit('itemsStatuses', copiaStatuses);
                    commit('isLoading', false);
                })
                .catch((error) => {
                    copiaStatuses[`${params.kyc_name}`].error = error.response
                    copiaStatuses[`${params.kyc_name}`].requestDocument = false

                    commit('itemsStatuses', copiaStatuses);
                    commit('isLoading', false);
                    commit('error', error?.response || error.message)
                });
        },
        async uploadDocumentShareholder({commit, state}, params) {
            let copiaStatuses = {...state.itemsStatuses}
            copiaStatuses[`${params.kyc_name}`].error = null

            await httpClientV1.post(`/shareholders/${params.shareholder_id}/kyc_documents`, {...params})
                .then((response) => {
                    state.items.shareholders.map((item, index) => {
                        item.map((document, indexJ) => {
                            if (document.source_id === params.shareholder_id && document.kyc_name === params.kyc_name) {
                                state.items.shareholders[index][indexJ] = response.data
                                return ''
                            }
                        })
                    })

                    let element = {...state.items}
                    commit('items', element);

                    copiaStatuses[`${params.kyc_name}`].requestDocument = false
                    commit('itemsStatuses', copiaStatuses);
                    commit('isLoading', false);
                })
                .catch((error) => {
                    copiaStatuses[`${params.kyc_name}`].error = error.response
                    copiaStatuses[`${params.kyc_name}`].requestDocument = false

                    commit('itemsStatuses', copiaStatuses);
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async requestDocumentationShareholders({commit}, params) {
            commit('isSaving', true);
            MyFunctions.dispatchTrackEvent("BackofficeKYC.Solicitardocrechazada.Click");
            await httpClientV1.post(`/carriers/${params.carrier_id}/request_documents`, {documents: params.documents || []})
                .then(() => {
                    const e = {type: 'success', data: {data: 'Solicitud de documentos notificado'}}
                    commit('app/reportMessage', e, {root: true});
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {
                        type: 'error',
                        data: {data: 'Error al solicitar documentos', code: error?.response?.code}
                    };
                    commit('app/reportMessage', e, {root: true});
                })
        },
    }
}