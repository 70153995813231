import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import app from './modules/app';
import security from './modules/security'
import mati_config from './modules/mati_config';
import business_clients from './modules/business_clients';
import providers from "@/plugins/store/modules/suppliers";
import invoices_loads from "@/plugins/store/modules/invoices_loads";
import carriers_reports from "@/plugins/store/modules/carriers_reports";
import moral_person from "@/plugins/store/modules/moral_person";
import shareholders from "@/plugins/store/modules/shareholders";
import credit_line from "@/plugins/store/modules/credit_line";
import documentsTable from "@/plugins/store/modules/audita/documentsTable";
import podValidationRules from "@/plugins/store/modules/audita/podValidationRules";
import childrenDocuments from "@/plugins/store/modules/audita/childrenDocuments";
import feature_flag from "@/plugins/store/modules/feature_flag";


// import Cookies from "js-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        security,
        mati_config,
        business_clients,
        providers,
        invoices_loads,
        carriers_reports,
        moral_person,
        shareholders,
        credit_line,
        documentsTable,
        podValidationRules,
        childrenDocuments,
        feature_flag
        //TODO: ADD ROUTER MODULES
    },
    state: {
        //YOU CAN DEFINE ALL STATE VARIABLES HERE
    },
    getters: {
        //TO GET STATE
    },
    mutations: {
        //HERE IS WHERE THE STATE CHANGE
    },
    actions: {
        //HERE YOU CAN CALL ASYNC METHODS TO MUTATE THE STATE
    },
    plugins: [createPersistedState({
        // storage: {
        //   getItem: (key) => Cookies.get(key),
        //   setItem: (key, value) =>
        //     Cookies.set(key, value, { expires: new Date(new Date().getTime() + 60 * 60 * 1000), secure: true }),
        //   removeItem: (key) => Cookies.remove(key),
        // },
        paths: ['security'],
    })],
});