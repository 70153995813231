<template>
    <v-app>
        <globals-messages/>
        <component v-bind:is="getLayout"></component>
    </v-app>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import AppLayout from './layouts/AppLayout.vue';
import {routes_permissions} from "@/utils/constant";

export default {
    name: "App",
    data: () => {
        return {
            identifyIntervalVerifyToken: null
        }
    },
    async mounted() {
        await this.fetchFeatureFlagsEnv();
    },
    computed: {
        ...mapGetters("app", ["getLayout"]),
        ...mapGetters("feature_flag", {feature_flag_item: "item"}),
        ...mapGetters("security", {token: "getJwt"})
    },
    methods: {
        ...mapActions("feature_flag", ["fetchFeatureFlagsEnv"]),
        ...mapMutations("app", ["addMenu", "resetMenu"]),
    },
    components: {
        'app-layout': AppLayout,
        GlobalsMessages: () => import('@/components/app/GlobalsMessages'),
    },
    watch: {
        feature_flag_item: {
            deep: true,
            handler: function (v) {
                if (!v.disabled_solvento_audita) {
                    this.resetMenu();
                    this.addMenu({
                        id: 'proofs-of-delivery-menu',
                        name: "Pruebas de entrega",
                        link_to: "pod",
                        permissions: routes_permissions['clients-and-suppliers'],
                        icon: require('@/assets/img/home/folder.svg'),
                        iconOff: require('@/assets/img/home/folder2.svg')
                    });
                } else {
                    this.resetMenu();
                    if (this.$router.currentRoute.name === 'pod') {
                        this.$router.push('/');
                    }
                }
            }
        },
    }
};
</script>

<style>
@import './assets/style/main.scss';
</style>