export default {
    iconfont: 'fa4',
    values: {
        address_card: 'fa-address-card',
        arrowright: 'fa-arrow-right',
        bars: 'fa-bars',
        book: 'fa-book',
        building: 'fa-building-o',
        ban: "fa-ban",
        van: 'fa-bus',
        cancel: 'fa-times',
        calendar: 'fa-calendar',
        car: 'fa-car',
        check: 'fa-check',
        check_circle: 'fa-check-circle-o',
        chevron_down: 'fa-chevron-down',
        chevron_right: 'fa-chevron-right',
        chevron_left: 'fa-chevron-left',
        circle: 'fa-circle',
        chart_bar: 'fa-bar-chart-o',
        clock: 'fa-clock-o',
        cloud_download: 'fa-cloud-download',
        cogs: 'fa-cogs',
        database: 'fa-database',
        download: 'fa-download',
        edit: 'fa-edit',
        error: 'fa-exclamation-triangle',
        external_link: 'fa-external-link',
        file_excel: 'fa-file-excel-o',
        file_pdf: 'fa-file-pdf-o',
        file_text: 'fa-file-text-o',
        file_code: 'fa-file-code-o',
        file_archive: 'fa-file-archive-o',
        file_image: 'fa-file-image-o',
        folder_open: 'fa-folder-open',
        home: 'fa-home',
        industry: 'fa-industry',
        id_card: 'fa-id-card-o',
        list_alt: 'fa-list-alt',
        lock: 'fa-lock',
        mail: 'fa-envelope-o',
        minus_circle: 'fa-minus-circle',
        motorcycle: 'fa-motorcycle',
        object_group: 'fa-object-group',
        paperclip: 'fa-paperclip',
        paper_plane: 'fa-paper-plane',
        pencil: 'fa-pencil',
        pie_chart: 'fa-pie-chart',
        play: 'fa-play',
        plus_cirlce: 'fa-plus',
        puzzle_piece: 'fa-puzzle-piece',
        refresh: 'fa-refresh',
        search: 'fa-search',
        shield: 'fa-shield',
        sign_out: 'fa-sign-out',
        sliders: 'fa-sliders',
        share_alt: 'fa-share-alt',
        stop_circle: 'fa-stop-circle-o',
        tachometer: 'fa-tachometer',
        times: 'fa-times',
        truck: 'fa-truck',
        upload: 'fa-upload',
        user: 'fa-user',
        users: 'fa-users',
        excel_file: 'fa-file-excel-o',
        cloud_upload: 'fa-cloud-upload',
        eye: 'fa-eye',
        eye_off: 'fa-eye-slash',
        save: 'fa-save'
    }
}