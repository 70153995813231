import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV1} from "@/shared/general-request";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {

        fetchClients({commit}, query = '') {
            commit('isLoading', true);
            commit('error', null);
            httpClientV1.get(`/accounts_payable/business/invoices_load${query}`)
                .then(async function (response) {
                    // console.log('response.data.data', response.data.data);

                    commit('isLoading', false);
                    commit('items', response.data.business);
                    commit('itemsPerPage', response.data.items_per_page);
                    commit('total', response.data.total_items);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        fetchClients1({commit}, query = '') {
            commit('isLoading', true);
            commit('error', null);

            httpClientV1.get(`/accounts_payable/business${query}`)
                .then(async function (response) {

                    commit('isLoading', false);
                    commit('items', response.data.business);
                    commit('itemsPerPage', response.data.items_per_page);
                    commit('total', response.data.total_items);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
    }
}