import {AxiosError, AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders} from 'axios'
import store from '@/plugins/store'
import i18n from '@/plugins/i18n/index'
import axios from 'axios';
import {HttpStatus} from '@/shared/general-request/enums/HttpStatus';
import {RefreshResponse} from '@/shared/general-request/interfaces/RefreshResponse';

export const authInterceptor = (
    config: AxiosRequestConfig,
    token: string
) => {
    if (!config.headers) {
        config.headers = {}
    }
    config.headers['Authorization'] = token
    return config
}

const reportSessionExpiredMessage = () => {
    console.log('reportSessionExpiredMessage')
    store.commit('app/reportMessage', {
        type: 'error',
        data: {data: i18n.t('Your session has expired, please log in again.')}
    })
}

const forceLogout = () => {
    store.dispatch('security/logout', null, {root: true}).then()
}

const processingUnauthorizedError = () => {
    reportSessionExpiredMessage()
    forceLogout()
}

export const refreshToken = async (error: AxiosError) => {
    const originalRequest = error.config

    const refreshToken = store.state?.security.refreshToken;
    let token
    try {
        const refreshResponse: AxiosResponse<RefreshResponse> = await axios.post('/api/v1/auth/refresh', {refreshToken})
        token = refreshResponse.data.data.token
        store.commit('security/setJwt', token);
    } catch (e) {
        processingUnauthorizedError()
    }

    if (axios.defaults.headers) {
        (axios.defaults.headers.common as AxiosRequestHeaders)['Authorization'] = `Bearer ${token}`;
    }

    if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
    }
    try {
        const originalRequestResponse = await axios(originalRequest);
        return originalRequestResponse.data;
    }catch (error) {
        return Promise.reject(error)
    }
}

export const errorInterceptor = async (error: AxiosError) => {
    if (error.response?.status === HttpStatus.UNAUTHORIZED) {
        return await refreshToken(error);
    }
    return Promise.reject(error)
}
