import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV2} from "@/shared/general-request";
export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchValidationRules({commit}, payload = {}) {
            const rules = {
                "rules": [
                    {
                        "rule_id": "some_uuid",
                        "prompt": "La FECHA de la FACTURA debe ser igual a la FECHA de la REMISIÓN"
                    },
                    {
                        "rule_id": "other_uuid",
                        "prompt": "El ORIGEN del PEAJE debe ser igual a CDMX"
                    }
                ],
                "typesAndFields": {
                    "INVOICE": [
                        {
                            "name": "Fecha",
                            "type": "Date"
                        }
                    ],
                    "REMITTANCE": [
                        {
                            "name": "Fecha",
                            "type": "Date"
                        },
                        {
                            "name": "Origen",
                            "type": "string"
                        }
                    ],
                    "TOLL": [
                        {
                            "name": "Destino",
                            "type": "string"
                        },
                        {
                            "name": "Cuño",
                            "type": "Boolean"
                        }
                    ],
                    "WEIGHING": [
                        {
                            "name": "Destino",
                            "type": "string"
                        },
                        {
                            "name": "Cuño",
                            "type": "Boolean"
                        }
                    ],
                    "UNKNOWN": []
                }
            };

            commit('error', null);
            commit('isLoading', true);
            commit('item', null);
            const {supplierId, loadId, deliveryId} = payload;
            const url = `/suppliers/${supplierId}/loads/${loadId}/deliveries/${deliveryId}/rules`;
            httpClientV2.get(url)
                .then(async function (response) {
                    commit('isLoading', false);
                    commit('item', response.results);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    console.log("error", error);
                    commit("item", rules);
                });
        },
    }
}
