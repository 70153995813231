import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV1, httpClientV2} from "@/shared/general-request";
export default {
	namespaced: true,
	state: {
		...defaultState,
	},
	getters: {
		...getters
	},
	mutations: {
		...mutations
	},

	actions: {
		fetchInvoicesLoadsGrouped({commit}, business_id) {
		commit('isLoading', true);
		commit('error', null);
		commit('items', []);
		httpClientV1.get(`/accounts_payable/invoices/bulk?business_id=${business_id}`)
			.then(async function (response) {
				commit('isLoading', false);
				commit('items', response.data.invoice_loads);
			})
			.catch((error) => {
				commit('isLoading', false);
				commit('error', error?.response?.code || error.message)
			});
		},
		fetchInvoicesLoads({commit}, queryParams) {
			const url = `/search/payment-orders?${queryParams}`;
			commit('isLoading', true);
			commit('error', null);
			commit('items', []);
			httpClientV2.get(url)
				.then(async function (response) {
					commit('isLoading', false);
					commit('items', response.results);
					commit('total', response.total);
				})
				.catch((error) => {
					commit('isLoading', false);
					commit('error', error?.response?.code || error.message)
				});
		}
	}
}