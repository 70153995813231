import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState"
import * as configcat from "configcat-js"
const {VUE_APP_CONFIGCAT_KEY: configCatApiKey} = process.env
export default {
    namespaced: true,
    state: {
        ...defaultState,
        item: {}
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        async fetchFeatureFlagsEnv({commit}) {
            const getAllValuesFromFeatureFlagService = async () => {
                const res = {};
                const settingValues = await configCatClient.getAllValuesAsync();

                settingValues.forEach((setting) => {
                    const {settingKey, settingValue} = setting;
                    res[settingKey] = settingValue;
                });
                commit('item', res);
            };

            const setCurrentFlagsOnChange = (configChanged) => {
                const res = {}
                const { settings } = configChanged
                Object.keys(settings).forEach(key =>{
                    res[key] = settings[key].value
                })
                commit('item', res);
            }
            const configCatClient = configcat.getClient(
                configCatApiKey,
                configcat.PollingMode.AutoPoll,
                {
                    pollIntervalSeconds: 5,
                    setupHooks: (hooks) =>
                        hooks.on('configChanged', (configChanged) => {
                            setCurrentFlagsOnChange(configChanged)
                        }),
                }
            );

            await getAllValuesFromFeatureFlagService();
        },
    }
}