import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
  },
  actions: {

  }
}