import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import {httpClientV1} from "@/shared/general-request";

const name = 'shareholders'

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetch({commit}, source_id) {
            commit('isLoading', true);
            let params = {source_id: source_id}

            await httpClientV1.get(`/${name}/by_source`, {params})
                .then((response) => {
                    commit('items', response.data.shareholders);
                    commit('isLoading', false);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async update({commit, state}, params) {
            commit('isSaving', true);
            await httpClientV1.patch(`/${name}/${params.shareholder_id}`, params)
                .then((response) => {
                    let foundIndex = state.items.findIndex(item => item.id === response.data.id)
                    state.items[foundIndex] = response.data
                    let element = [...state.items]
                    commit('items', element);
                    commit('isSaving', false);
                })
                .catch((error) => {
                    commit('isSaving', false);
                    commit('error', error?.response?.code || error.message)
                });
        },
        async create({commit, state}, params) {
            commit('isSaving', true);
            await httpClientV1.post(`/${name}`, params)
                .then((response) => {
                    let element = [...state.items, response.data]
                    commit('items', element);
                    commit('isSaving', false);
                })
                .catch((error) => {
                    commit('isSaving', false);
                    commit('error', error?.response || error.message)
                });
        },
        async requestDocuments({commit}, params) {
            commit('isSaving', true);
            await httpClientV1.post(`/shareholders/${params.id}/trigger_email`, {user_id: params.user_id})
                .then(() => {
                    commit('isSaving', false);
                })
                .catch((error) => {
                    commit('isSaving', false);
                    commit('error', error?.response || error.message)
                })
        },
        async requestBlackList({commit, state}, params) {
            let copiaStatuses = {...state.itemsStatuses}

            commit('isSaving', true);
            await httpClientV1.get(`/shareholders/${params.id}/black_list`)
                .then(() => {
                    copiaStatuses[params.id].errorBlackList = false
                    commit('itemsStatuses', copiaStatuses);
                    commit('isSaving', false);
                })
                .catch((error) => {
                    copiaStatuses[params.id].errorBlackList = error.response
                    commit('itemsStatuses', copiaStatuses);
                    commit('isSaving', false);
                    commit('error', error?.response || error.message)
                })
        }
    }
}