import claves_lada_solo from "@/components/app/classes/utils/claves_lada_solo";
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import { hotjarSiteId, mixpanelSiteId } from '@/config/app.config';

export const MyFunctions = {
    dispatchSurveyEvent: function (eventName) {
        if (hotjarSiteId) Vue.prototype.Hotjar.event(eventName);
    },
    errorDataToi18n: function (errorData) {
        if (!errorData || !errorData?.code || !errorData?.data) return null;
        const i18nErrorData = structuredClone(errorData);
        i18nErrorData.code = i18n.t(`EXCEPTIONS.CODES.${i18nErrorData.code}`);
        i18nErrorData.data.errors = errorData.data.errors.map(err => {
            const {params, message} = err;
            return {params: this.processesParams(params), message: this.processesMessages(message)};
        });
        return i18nErrorData;
    },
    dispatchTrackEvent: function (eventName, error_data) {
        const i18n_error_data = this.errorDataToi18n(error_data);
        if (mixpanelSiteId) Vue.prototype.mixpanel.track(eventName, i18n_error_data);
        this.dispatchSurveyEvent(eventName);
    },
    identifyDnDataAnalysisSites: function (id, data = {}) {
        if (hotjarSiteId)
            Vue.prototype.Hotjar.identify(id, data);
        if (mixpanelSiteId)
            Vue.prototype.mixpanel.identify(id, data);
    },
    setPersonalInfoDnDataAnalysisSites: function (data) {
        if (mixpanelSiteId)
            Vue.prototype.mixpanel.people.set(data);
    },
    scrollToBottom: function (id) {
        var div = document.getElementById(id);
        const y = div.getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    },
    checkLength: function (cad) {
        return cad.length >= 8 && cad.length <= 16;
    },
    checkUpperLowerCase: function (cad) {
        return /(?=.*[a-z])(?=.*[A-Z])/.test(cad) || false;
    },
    checkDigit: function (cad) {
        return /(?=.*?[0-9])/.test(cad) || false;
    },
    checkSymbols: function (cad) {
        return /(?=.*\W)/.test(cad) || false;
    },
    cleanPhone: function (phone) {
        if (!phone) return '';
        return phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    },
    claveLada2digits: function (phone) {
        if (!phone) return false;
        let m2 = phone.substring(0, 2).length == 2 ? phone.substring(0, 2) : null;

        return claves_lada_solo[m2] || null;
    },
    claveLada3digits: function (phone) {
        if (!phone) return false;
        let m3 = phone.substring(0, 3).length == 3 ? phone.substring(0, 3) : null;

        return claves_lada_solo[m3] || null;
    },
    claveLadaIncorrecta: function (phone) {
        if (!phone) return true;
        const clean = this.cleanPhone(phone);
        return !this.claveLada2digits(clean) && !this.claveLada3digits(clean);
    },
    fontSizeCalc: function (breakpoint) {
        switch (breakpoint) {
            case'xs':
                return '1rem';
            case'sm':
                return '2rem';
            case'md':
                return '2rem';
            case'lg':
                return '2rem';
            case'xl':
                return '3rem';
        }
    },
    cleanObject: function (obj) {
        Object.keys(obj).forEach((k) => (obj[k] == null || obj[k] == '' || obj[k] == false) && delete obj[k]);
        return obj;
    },
    createInitials: function (name) {
        return name
            .split(" ", 2)
            .map((str) => str[0])
            .join("");
    },
    processesParams: function (params) {
        const array_params = params.split(',');
        const formatted = array_params.map((param) => {
            return this.translate_info(param);
        });
        return formatted.length !== 1 ? formatted.join(',') : formatted.join('');
    },
    processesMessages: function (messages) {
        const array_messages = messages.split(',');
        const formatted = array_messages.map((message) => {
            return this.translate_info(message, 'MESSAGES');
        });
        return formatted.length !== 1 ? formatted.join(', ').replaceAll(';', ',') : formatted.join('').replaceAll(';', ',');
    },

    replaceById: function (array, id, newElement) {
        const index = array.findIndex(element => element.id === id);

        if (index !== -1) {
            array.splice(index, 1, newElement);
        }
    },
    copyToClipboard: async function (mytext) {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(mytext);
            }
        } catch ($e) {
            console.log("Cannot copy to clipboard!!!");
        }
    },
    sortDates: function (dates) {
        return dates.sort((dateA, dateB) => Vue.prototype.$dayjs(dateA).diff(Vue.prototype.$dayjs(dateB)));
    },
    thousandsSeparationFormat: function (number) {
        const fixed_number = number.toFixed(2);
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        let arr = fixed_number.toString().split('.');
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join('.') : arr[0];
    },
};