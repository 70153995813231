export default [
    {
        path: 'proof-of-deliveries',
        component: () => import('@/modules/proof-of-deliveries/views/ProofOfDeliveries.vue'),
        name: 'pod',
        redirect: { name: 'pod-list' },
        children: [
            {
                path: '/',
                name: 'pod-list',
                component: () => import('@/modules/proof-of-deliveries/views/ProofOfDeliveriesList.vue')
            },
            {
                path: ':documentId/detail',
                name: 'pod-detail',
                component: () => import('@/modules/proof-of-deliveries/views/ProofOfDeliveryDetail.vue')
            }
        ],
    }
]
